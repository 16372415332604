// REF-10
// prettier-ignore
/* eslint-disable max-len */
export const TRASH_ICON_SVG_STRING = 
`<?xml version="1.0" standalone="no"?>
<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.0//EN" "http://www.w3.org/TR/2001/REC-SVG-20010904/DTD/svg10.dtd">
<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" id="body_1" width="16" height="15">
  <g transform="matrix(1.0666668 0 0 1.0714285 0 0)">
    <g transform="matrix(0.027142858 0 0 0.027142858 0 0)">
      <g>
        <path d="M459.21 156.28L448.835 436.32C 448.6592 441.1247 444.4248 445.195 439.6006 445.195L439.6006 445.195L260.40057 445.195C 255.58028 445.195 251.34198 441.1091 251.16617 436.32L251.16617 436.32L240.79117 156.28C 240.50601 148.5534 234.00996 142.522 226.28317 142.807C 218.55658 143.09607 212.52518 149.58821 212.81018 157.315L212.81018 157.315L223.18118 437.355C 223.91946 457.195 240.51718 473.195 260.40018 473.195L260.40018 473.195L439.60016 473.195C 459.47516 473.195 476.08017 457.22202 476.81915 437.355L476.81915 437.355L487.19016 157.315C 487.4753 149.58841 481.44406 143.09601 473.71716 142.807C 465.99057 142.52185 459.49417 148.5531 459.20917 156.28L459.20917 156.28L459.21 156.28z" stroke="none" fill="#000000" fill-rule="nonzero" />
        <path d="M338.8 212.8L338.8 403.2C 338.8 409.3875 343.81168 414.39902 349.999 414.39902C 356.1863 414.39902 361.198 409.38733 361.198 403.2L361.198 403.2L361.198 212.80002C 361.198 206.61252 356.1863 201.60101 349.999 201.60101C 343.81168 201.60101 338.8 206.61272 338.8 212.80002L338.8 212.80002L338.8 212.8z" stroke="none" fill="#000000" fill-rule="nonzero" />
        <path d="M277.2 213.13L282.8016 403.53C 282.9852 409.7136 288.14142 414.581 294.32462 414.397C 300.5082 414.21732 305.3756 409.0572 305.19162 402.874L305.19162 402.874L299.59003 212.474C 299.41034 206.2904 294.2502 201.423 288.06702 201.607C 281.88342 201.78668 277.02002 206.9468 277.2 213.12999L277.2 213.12999L277.2 213.13z" stroke="none" fill="#000000" fill-rule="nonzero" />
        <path d="M400.41 212.47L394.8084 402.87C 394.62482 409.0536 399.492 414.214 405.6754 414.393C 411.859 414.5766 417.0154 409.7094 417.19843 403.526L417.19843 403.526L422.80002 213.126C 422.9797 206.94241 418.11642 201.78201 411.933 201.60301C 405.74942 201.41942 400.58902 206.2866 400.41 212.47002L400.41 212.47002L400.41 212.47z" stroke="none" fill="#000000" fill-rule="nonzero" />
        <path d="M210 162.4L490 162.4C 497.7305 162.4 504 156.1344 504 148.4C 504 140.6695 497.7305 134.4 490 134.4L490 134.4L210 134.4C 202.2695 134.4 196 140.6695 196 148.4C 196 156.1344 202.2695 162.4 210 162.4z" stroke="none" fill="#000000" fill-rule="nonzero" />
        <path d="M307.46 143.85L313.2881 123.44401C 314.5381 119.076805 320.20218 114.803406 324.75308 114.803406L324.75308 114.803406L375.2451 114.803406C 379.7959 114.803406 385.46008 119.07291 386.71008 123.44401L386.71008 123.44401L392.53818 143.85L419.46017 136.15471L413.63208 115.75271C 408.94458 99.35771 392.29608 86.80371 375.2451 86.80371L375.2451 86.80371L324.75308 86.80371C 307.7021 86.80371 291.05008 99.36271 286.3661 115.75271L286.3661 115.75271L280.538 136.15471L307.46 143.85z" stroke="none" fill="#000000" fill-rule="nonzero" />
      </g>
    </g>
  </g>
</svg>`;
